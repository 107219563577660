<script setup lang="ts">
   import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline';
   const { locale } = useI18n();

   const { currentRegisterSteps } = storeToRefs(useClientAuthStore());
   const { getPreviousStep } = useClientAuthStore();
   const route = useRoute();
   const { clientProfile } = storeToRefs(useClientInfo());

   const router = useRouter();
   const currentStep = computed(() => {
      const routeName = route.name as string;
      return currentRegisterSteps.value.findIndex(
         (step) => step?.to?.name === routeName
      );
   });
   const routePrevious = () => {
      const to = getPreviousStep()?.to;
      if (to) {
         router.push(to);
      } else {
         router.back();
      }
   };
   const cantBack = computed(
      () => clientProfile.value && currentStep.value == 1
   );
</script>

<template>
   <nav aria-label="Progress" class="flex gap-2 my-6" v-if="currentStep != -1">
      <div
         v-for="(step, index) in currentRegisterSteps"
         :key="index"
         class="flex-1"
      >
         <a
            class="flex h-2 rounded"
            :class="{
               'bg-secondary-400': index < currentStep || index == currentStep,
               'bg-gray-40': true,
            }"
         >
         </a>
      </div>
   </nav>
   <div
      v-if="!cantBack"
      class="mb-6 cursor-pointer"
      @click.prevent="routePrevious"
   >
      <ArrowLeftIcon v-if="locale == 'en'" class="h-6 w-6 text-secondary-400" />
      <ArrowRightIcon v-else class="h-6 w-6 text-secondary-400" />
   </div>
</template>
